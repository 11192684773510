import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import router from '@/router'
import store from '@/store'
import { ILetterTemplate, LetterTemplateItemState } from './types'
import { createLetterTemplate, fetchLetterTemplate, updateLetterTemplate } from '@/api/letterTemplates'

// eslint-disable-next-line import/prefer-default-export
export const appLetterTemplate = createModule(
  'appLetterTemplate',
  {
    namespaced: true,
    state: {
      isLoading: false,
      letterTemplate: {
        name: '',
        body: '',
      },
      isButtonDisabled: false,
    } as LetterTemplateItemState,
    getters: {
      getIsLoading: state => state.isLoading,
      getLetterTemplate: state => state.letterTemplate,
      getIsButtonDisabled: state => state.isButtonDisabled,
    },
    mutations: {
      SET_IS_LOADING(state, val: boolean) {
        state.isLoading = val
      },
      SET_LETTER_TEMPLATE(state, val: ILetterTemplate) {
        state.letterTemplate = val
      },
      SET_IS_BUTTON_DISABLED(state, val: boolean) {
        state.isButtonDisabled = val
      },
      INIT_LETTER_TEMPLATE(state) {
        state.letterTemplate = {
          name: '',
          body: '',
        }
      },
    },
    actions: {
      async createLetterTemplate({ state }) {
        try {
          appLetterTemplate.mutations.SET_IS_BUTTON_DISABLED(true)
          const response = await createLetterTemplate(state.letterTemplate)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'New Letter template was successfully created',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
          appLetterTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
          return response
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating Letter template',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return error
        } finally {
          appLetterTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
        }
      },
      async fetchLetterTemplate(ctx, id) {
        try {
          appLetterTemplate.mutations.SET_IS_LOADING(true)
          const response = await fetchLetterTemplate(id)
          appLetterTemplate.mutations.SET_LETTER_TEMPLATE(response.data.data)
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Letter template item',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appLetterTemplate.mutations.SET_IS_LOADING(false)
        }
      },
      async updateTemplate({ state }) {
        try {
          const updatedLetterTemplate = {
            id: state.letterTemplate.id,
            name: state.letterTemplate.name,
            body: state.letterTemplate.body,
          }

          appLetterTemplate.mutations.SET_IS_BUTTON_DISABLED(true)

          await updateLetterTemplate(updatedLetterTemplate)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Letter Template was successfully updated',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
          appLetterTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating Letter Template',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appLetterTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
        }
      },
    },
  },
)

appLetterTemplate.register(store)
