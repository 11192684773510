



































































































import Vue from 'vue'
import {
  BOverlay,
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BLink,
  BButton,
} from 'bootstrap-vue'

import { tinyOptions } from '@core/mixins/tinyOptions'
import Editor from '@tinymce/tinymce-vue'
import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE'
import Ripple from 'vue-ripple-directive'
import AvailablePlaceholder from '@/views/components/available-placeholder/AvailablePlaceholder.vue'
import { appLetterTemplate } from '@/store/modules/app-letter-template-item'

export default Vue.extend({
  name: 'LetterTemplateItem',
  components: {
    BOverlay,
    BCard,
    BForm,
    BLink,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    Editor,

    AvailablePlaceholder,
  },
  directives: {
    Ripple,
  },
  mixins: [tinyOptions],
  computed: {
    isLoading() {
      return appLetterTemplate.getters.getIsLoading
    },
    letterTemplate() {
      return appLetterTemplate.getters.getLetterTemplate
    },
    isButtonDisabled() {
      return appLetterTemplate.getters.getIsButtonDisabled
    },
  },
  async created() {
    await this.setLetterTemplate()
  },
  beforeDestroy() {
    appLetterTemplate.mutations.INIT_LETTER_TEMPLATE()
  },
  methods: {
    async setLetterTemplate() {
      const letterTemplateID = this.$router.currentRoute.params.id
      if (letterTemplateID) {
        await appLetterTemplate.actions.fetchLetterTemplate(letterTemplateID)
      } else {
        appLetterTemplate.mutations.INIT_LETTER_TEMPLATE()
      }
    },
    async saveChanges() {
      const letterTemplateID = this.$router.currentRoute.params.id
      if (letterTemplateID) {
        await appLetterTemplate.actions.updateTemplate()
      } else {
        await appLetterTemplate.actions.createLetterTemplate()
      }
      this.$router.push({ name: 'admin-letter-templates' })
    },
    insertValueToEditor(val: string) {
      getTinymce().activeEditor.execCommand('mceInsertContent', false, val)
    },
  },
})
